import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useFormContext } from '../context/form.context'
import { useLangContext } from '../context/lang.context'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import slugify from '@sindresorhus/slugify'
import { ProductListing } from '../components/ProductListing'
import CollectionLinks from '../components/shopify/collection-tags'
import Grid from '../components/Grid'
import Button from '../components/Button'
import { colors, s } from '../style'

export default function Products({ data: { products, products2 } }) {
  const { formOpen, setFormOpen } = useFormContext()
  const { lang } = useLangContext()


  const shopifyProductList = products.nodes;

  const contentfulProductList = products2.nodes.filter(item => item['orderNo'] !== null);

  const productList = shopifyProductList.map(shopifyProduct => ({
    ...contentfulProductList.find(contentfulProduct => contentfulProduct.slug === shopifyProduct.handle),
    ...shopifyProduct,
  })).sort((a, b) => a.orderNo - b.orderNo);

  // console.log(productList);

  const button = {
    text: {
      pl: 'Kontakt z ekspertem',
      en: 'Query now',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout white>
      <Seo
        title={`Kontenery Morskie | Sklep z kontenerami morskimi`}
        desc={`✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`}
        image={'ogimage.jpg'}
        url={`sklep`}
      />
      <section css={sOuter}>
        <CollectionLinks />
        <h1 css={sHeadline}>Sklep</h1>
        <p css={sParagraph}>
          Przygotowaliśmy podstawową ofertę kontenerów dostępnych na naszych
          depotach. Są to kontenery z możliwością natychmiastowego zamówienia i
          krótkim terminem dostawy. W przypadku większych lub bardziej
          skomplikowanych zamówień zachęcamy do kontaktu z naszym ekspertem
        </p>
        <ProductListing products={productList} />
        <div css={sButtonWrapper}>
          <p css={sParagraph}>
            Jeśli Twoje zapotrzebowanie na kontenery jest bardziej złożone, zachęcamy do skorzystania z&nbsp;formularza kontaktowego na stronie.{' '}
            <br />
            Po przesłaniu informacji, przydzielamy opiekuna do Twojego zapytania, który przygotuje ofertę dostosowaną do indywidualnych potrzeb Twojego biznesu.
          </p>
          <Button noMargin reactive action={button.action}>
            {button.text[lang]}
          </Button>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    products: allShopifyProduct(
      sort: { fields: [publishedAt], order: ASC }
      limit: 100
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
    products2: allContentfulProdukt(
      sort: {order: ASC, fields: orderNo}
      filter: {node_locale: {eq: "pl"}}
    ) {
      nodes {
        slug
        orderNo
      }
    }
  }
`

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  maxWidth: '1500px',
  position: 'relative',
  top: 0,
  left: 0,
  margin: '0 auto',
  padding: '12rem 2rem 10rem',
  [s.xs]: {
    padding: '8rem 2rem',
  },
}

const sParagraph = {
  lineHeight: '2em',
  fontSize: '1rem',
  opacity: '0.6',
  marginBottom: '3rem',
  [s.md]: {
    fontSize: '1.15rem',
    padding: '0 2rem',
  },
}

const sHeadline = {
  margin: '0 auto 2rem',
  fontSize: '3rem',
  [s.md]: {
    padding: '0 2rem',
  },
}

const sButtonWrapper = {
  width: '100%',
  marginTop: '8rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  'p': {
    maxWidth: '800px',
  }
}
